import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"create_comments"},[_c(VForm,[_c(VContainer,[_c('h1',[_vm._v("Change Password")]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"type":"password","placeholder":"New Password","outlined":""},model:{value:(_vm.formData.new_password),callback:function ($$v) {_vm.$set(_vm.formData, "new_password", $$v)},expression:"formData.new_password"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"placeholder":"Confirm Password","type":"password","outlined":""},model:{value:(_vm.formData.confirm_password),callback:function ($$v) {_vm.$set(_vm.formData, "confirm_password", $$v)},expression:"formData.confirm_password"}})],1),_c(VBtn,{staticClass:"ma-2 white--text",attrs:{"loading":_vm.loading_form,"disabled":_vm.loading_form,"color":"blue-grey","outlined":""},on:{"click":_vm.change_the_password}},[_vm._v(" Add "),_c(VIcon,{attrs:{"right":"","dark":""}},[_vm._v(" mdi-cloud-upload ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }