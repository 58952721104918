import {API} from "@/env";
import axios from "@/api/axios";
import Vuex from "@/store/index";

export default {

    /**
     * This api for get all codes
     */
    get_all_code_data() {

        const options = {
            method: 'GET',
            url: API + 'api/get_all_codes',
            headers: {'content-type': 'application/json'},
        };
        return new Promise ((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error)
            });
        });

    },

    /**
     * This api for update codes
     */
    update_code_data(data,id) {

        const options = {
            method: 'POST',
            url: API + `api/change_index_code/${id}`,
            headers: {'content-type': 'application/json',  Authorization: 'Bearer ' + Vuex.state.admintoken },
            data: data,
        };
        return new Promise ((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error)
            });
        });

    },

    /**
     * This api for change admin password
     */
    change_password(data) {

        const options = {
            method: 'POST',
            url: API + 'api/changepassword',
            headers: {'content-type': 'application/json',  Authorization: 'Bearer ' + Vuex.state.admintoken },
            data: data,
        };
        return new Promise ((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error)
            });
        });

    },








    /**
     * Edit created page
     * @returns {*}
     */
    edit_Created_page(id, data) {
        const options = {
            method: 'POST',
            url: API + `api/edit-created-page/${id}`,
            headers: {'content-type': 'multipart/form-data', Authorization: 'Bearer ' + Vuex.state.admintoken },
            data: data,
        };

        return new Promise ((resolve, reject) => {
            axios(options).then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error)
            });
        });
    },
}


/**
 * This api for get all users
 *  return all users data
 */
export const user_data = (params) => {

    const options = {
        method: 'GET',
        url: API + 'api/getAllUsers',
        headers: {'content-type': 'application/json',  Authorization: 'Bearer ' + Vuex.state.token },
        params: {...params},
    };
    return new Promise ((resolve, reject) => {
        axios(options).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error)
        });
    });

}

/**
 * add new comment
 */
export const add_new_comment = (data) => {

    const options = {
        method: 'POST',
        url: API + 'api/addNewComment',
        headers: {'content-type': 'multipart/form-data',  Authorization: 'Bearer ' + Vuex.state.token },
        data: data,
    };
    return new Promise ((resolve, reject) => {
        axios(options).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error)
        });
    });

}

/**
 * get comments
 */
export const get_all_comments = () => {

    const options = {
        method: 'GET',
        url: API + 'api/get_comments',
        headers: {'content-type': 'multipart/form-data' },
    };
    return new Promise ((resolve, reject) => {
        axios(options).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error)
        });
    });
}

/**
 * Get All crated page list
 * @returns {*}
 */
export const get_created_page_list = () => {
    const options = {
        method: 'GET',
        url: API + 'api/get-new-pages-list',
        headers: {'content-type': 'application/json', Authorization: 'Bearer ' + Vuex.state.token },
    };

    return new Promise ((resolve, reject) => {
        axios(options).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error)
        });
    });
}

/**
 * Create new page
 * @data data
 * @returns {*}
 */
export const create_new_page = (data) => {
    const options = {
        method: 'POST',
        url: API + 'api/create-new-page',
        headers: {'content-type': 'multipart/form-data',  Authorization: 'Bearer ' + Vuex.state.token },
        data: data,
    };

    return new Promise ((resolve, reject) => {
        axios(options).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error)
        });
    });

}


/**
 *
 * @param page_name
 * @returns {*}
 */
export const get_created_page = (page_name = 'home_page') =>  {
    const options = {
        method: 'GET',
        url: API + `api/get-created-page/${page_name}`,
        headers: {'content-type': 'application/json' },
    };

    return new Promise ((resolve, reject) => {
        axios(options).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error)
        });
    });
}

/**
 * This api for change admin password
 */
export const change_password = (data)  => {

    const options = {
        method: 'POST',
        url: API + 'api/changepassword',
        headers: {'content-type': 'application/json',  Authorization: 'Bearer ' + Vuex.state.token },
        data: data,
    };
    return new Promise ((resolve, reject) => {
        axios(options).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error)
        });
    });

}

/**
 * This api for add script code
 */
export const change_script = (data)  => {

    const options = {
        method: 'POST',
        url: API + 'api/add-new-script',
        headers: {'content-type': 'application/json',  Authorization: 'Bearer ' + Vuex.state.token },
        data: data,
    };
    return new Promise ((resolve, reject) => {
        axios(options).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error)
        });
    });

}

